.custom-card {
}

.custom-card .card-header .user-bg {
  height: 200px;
  width: 100%;
}

.custom-card .card-profile .user-img {
  height: 80px;
  width: 80px;
}

.mt-1 a {
  color: white;
}

/* .bg {
  background-color: #031a09;
  height: 135px;
  border-radius: 50%;
  margin-top: -100px;
  transform: scale(1.5);
} */