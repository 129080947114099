.filter-wrapper {
  width: 300px;
  background-color: white;
  position: absolute;
  right: -301px;
  top: 0;
  overflow-y: scroll;
  max-height: 100%;
  z-index: 1;
  transition: right 0.5s;
}

.filter-toggle-true {
  right: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.filter-toggle-false {
  right: -3O1px;
}

.filter-wrapper.active {
  right: -301px;
}

.filter-wrapper {
  padding: 10px;
}

.input-wrapper {
  padding: 5px;
}

.input-wrapper input, select {
  width: 100%;
  height: 30px;
}

.search-btn {
  display: flex;
  justify-content: center;
}